import React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';

import EnvironnementShopHero from '@/containers/EnvironnementShop/EnvironnementShopHero';
import EnvironnementShopStats from '@/containers/EnvironnementShop/EnvironnementShopStats';
import EnvironnementShopPresentation from '@/containers/EnvironnementShop/EnvironnementShopPresentation';
import EnvironnementShopContactBox from '@/containers/EnvironnementShop/EnvironnementShopContactBox';

import {
  EnvironnementShopPageQuery,
  PrismicEnvironnementShopPageEnvironnementGroupType,
} from '../../../graphql-types';

interface Props {
  data: EnvironnementShopPageQuery;
  type: string;
}

const EnvironnementShopContainerRoot = styled.section`
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  background-color: ${({ theme }) => theme.color.black.main};
`;

const EnvironnementShopContainer = ({ data, type }: Props): JSX.Element => {
  const {
    hero_title,
    hero_content,
    hero_background,
    environnement,
    ambition_title,
    ambition_content,
  } = data?.prismicEnvironnementShopPage?.data;

  const { primary } = data?.prismicEnvironnementShopPageBodyContactbox || {};

  const companyStats =
    data?.prismicEnvironnementShopPageBody1Companystats || {};

  const HeroProps = {
    title: <RichText render={hero_title?.raw} />,
    content: <RichText render={hero_content?.raw} />,
    background: {
      url: hero_background?.url,
      alt: hero_background?.alt,
    },
  };

  const EnvironnementProps = {
    environnement: environnement?.map(
      (el: PrismicEnvironnementShopPageEnvironnementGroupType | null) => {
        const { environnement_title, environnement_content } = el || {};

        return {
          title: <RichText render={environnement_title?.raw} />,
          content: <RichText render={environnement_content?.raw} />,
        };
      },
    ),
    ambition: {
      title: <RichText render={ambition_title?.raw} />,
      content: <RichText render={ambition_content?.raw} />,
    },
  };

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  const StatsProps = {
    stats: companyStats?.items?.map((item) => ({
      number: <RichText render={item?.number?.raw} />,
      label: item?.label?.text,
    })),
  };

  return (
    <EnvironnementShopContainerRoot>
      <EnvironnementShopHero {...HeroProps} />
      <EnvironnementShopPresentation {...EnvironnementProps} />
      {primary && <EnvironnementShopContactBox {...ContactBoxProps} />}
      {companyStats?.items?.length > 0 && (
        <EnvironnementShopStats {...StatsProps} />
      )}
    </EnvironnementShopContainerRoot>
  );
};

export default EnvironnementShopContainer;
