import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Grid from '@/components/atoms/Grid';

import mq from '@/styles/mq';

import Typography from '@/components/atoms/Typography';

interface EnvironnementShopProps {
  title: React.ReactNode;
  content: React.ReactNode;
}

interface Props {
  environnement: EnvironnementShopProps[];
  ambition: {
    title: React.ReactNode;
    content: React.ReactNode;
  };
}

const EnvironnementShopPresentationRoot = styled(Grid)`
  background-color: ${({ theme }) => theme.color.black.main};
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  ${mq('xs')} {
    padding-top: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};
    padding-left: ${({ theme }) => theme.spacing(5)};
  }
  ${mq('md')} {
    padding-right: ${({ theme }) => theme.spacing(10)};
    padding-left: ${({ theme }) => theme.spacing(10)};
    flex-direction: row;
    justify-content: space-between;
  }
`;

const EnvironnementShopItemRoot = styled.section`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column !important;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  ${mq('sm')} {
    margin-bottom: ${({ theme }) => theme.spacing(17)};
  }
  ${mq('md')} {
    width: 39%;
  }
  ${mq('lg')} {
    flex-direction: row;
  }
`;

const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.color.white.main};
  /* background: -webkit-linear-gradient(
    90deg,
    rgba(47, 208, 114, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    rgba(47, 208, 114, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    90deg,
    rgba(47, 208, 114, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-attachment: fixed; */
`;

const Content = styled(Typography)`
  opacity: 0.7;
`;

const AmbitionContainer = styled(Grid)``;

const EnvironnementShopItem = ({
  title,
  content,
}: EnvironnementShopProps): JSX.Element => {
  return (
    <EnvironnementShopItemRoot>
      <Title variant="h3">{title}</Title>
      <Content as="div" variant="textMd" color="white">
        {content}
      </Content>
    </EnvironnementShopItemRoot>
  );
};

const EnvironnementShopPresentation = ({
  ambition,
  environnement,
}: Props): JSX.Element => {
  return (
    <EnvironnementShopPresentationRoot container>
      {environnement.map((env, index) => {
        const { title, content } = env;
        return (
          <EnvironnementShopItem
            key={index.toString()}
            title={title}
            content={content}
          />
        );
      })}
      <AmbitionContainer>
        <Title variant="h3">{ambition.title}</Title>
        <Content as="div" variant="textMd" color="white">
          {ambition.content}
        </Content>
      </AmbitionContainer>
    </EnvironnementShopPresentationRoot>
  );
};

export const query = graphql`
  fragment EnvironnementShopPresentation on PrismicEnvironnementShopPageDataType {
    environnement {
      environnement_title {
        raw
      }
      environnement_content {
        raw
      }
    }
    ambition_title {
      raw
    }
    ambition_content {
      raw
    }
  }
`;

export default EnvironnementShopPresentation;
