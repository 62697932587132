import React from 'react';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import CompanyStats from '@/components/molecules/CompanyStats';

import mq from '@/styles/mq';

interface StatProps {
  number?: React.ReactNode;
  label?: string | null;
}

interface Props {
  stats?: StatProps[];
}

const EnvironnementShopStatsRoot = styled(Container)`
  display: none;
  h2,
  div {
    color: ${({ theme }) => theme.color.textSecondary.main};
  }
  ${mq('lg')} {
    display: flex;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing(10)};
    padding-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;

const EnvironnementShopStats = ({ stats }: Props): JSX.Element => {
  return (
    <EnvironnementShopStatsRoot maxWidth="lg">
      <CompanyStats stats={stats} />
    </EnvironnementShopStatsRoot>
  );
};

export default EnvironnementShopStats;
