import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Grid from '@/components/atoms/Grid';
import Typography from '@/components/atoms/Typography';
import Background from '@/components/atoms/Background';

import mq from '@/styles/mq';

interface Props {
  background?: {
    url: string;
  };
  title?: React.ReactNode;
  content?: React.ReactNode;
}

const EnvironnementShopHeroRoot = styled(Grid)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.black.main};
  padding-top: ${({ theme }) => theme.spacing(0)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  ${mq('sm')} {
    flex-direction: row !important;
  }
  ${mq('xs')} {
    /* padding-right: ${({ theme }) => theme.spacing(5)}; */
    /* padding-left: ${({ theme }) => theme.spacing(10)}; */
    /* padding-top: ${({ theme }) => theme.spacing(6.6)}; */
    padding-bottom: ${({ theme }) => theme.spacing(5)};
  }
  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(8)};
  }
`;

const Section = styled(Background)`
  z-index: 500;
  box-sizing: border-box;
  /* width: 66.15%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  /* padding-bottom: ${({ theme }) => theme.spacing(2)}; */
  ${mq('xs')} {
    /* padding-right: ${({ theme }) => theme.spacing(5)}; */
    /* padding-left: ${({ theme }) => theme.spacing(10)}; */
    padding-top: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};
    padding-left: ${({ theme }) => theme.spacing(5)};
    /* padding-bottom: ${({ theme }) => theme.spacing(5)}; */
  }
  ${mq('md')} {
    /* padding-right: ${({ theme }) => theme.spacing(5)}; */
    /* padding-left: ${({ theme }) => theme.spacing(10)}; */
    padding-top: ${({ theme }) => theme.spacing(8)};
    padding-right: ${({ theme }) => theme.spacing(10)};
    padding-left: ${({ theme }) => theme.spacing(10)};
    /* padding-bottom: ${({ theme }) => theme.spacing(5)}; */
  }
  p {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

const Title = styled(Typography)`
  text-align: left;
`;

const Content = styled(Typography)`
  opacity: 0.7;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  text-align: left;
  ${mq('xs')} {
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;

const EnvironnementShopHero = ({
  title,
  content,
  background,
}: Props): JSX.Element => {
  return (
    <EnvironnementShopHeroRoot
      container
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="stretch"
    >
      <Section background={background} overlay={0.6}>
        <Title variant="h1" color="white">
          {title}
        </Title>
        <Content as="div" color="white" variant="textMd">
          {content}
        </Content>
      </Section>
    </EnvironnementShopHeroRoot>
  );
};

export const query = graphql`
  fragment EnvironnementShopHero on PrismicEnvironnementShopPageDataType {
    hero_title {
      raw
    }
    hero_content {
      raw
    }
    hero_background {
      alt
      url
    }
  }
`;

export default EnvironnementShopHero;
